import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";

export const pageQuery = graphql`
  {
    file(relativePath: { eq: "highway-intersection.jpg" }) {
      ...SplashImage
    }
  }
`;

export default function ContactPage({ data }) {
  const [status, setStatus] = useState("");
  const intl = useIntl();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async ({ name, email, message }) => {
    setStatus("Sending...");

    let details = {
      name: name,
      email: email,
      message: message,
    };

    fetch("https://ubicube-mailer.herokuapp.com/contact", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `email=${details.email}&name=${details.name}&message=${details.message}`,
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw Error("There was an Error!");
        } else {
          setStatus("Your message was successfully sent!");
        }
      })
      .catch(() => {
        setStatus("There was an Error submitting your Message :(");
      });
  };

  return (
    <Layout intl={intl} title="Contact Us">
      <Seo title="Contact Us" />
      <SplashTitle image={getImage(data.file)} subheadline={intl.formatMessage({ id: "contactus.subheadline", defaultMessage: "Contact us" })}>
        {intl.formatMessage({ id: "contactus.headline", defaultMessage: "Get in touch with ubicube" })}
      </SplashTitle>
      <main>
        <section className="bg-gray-100">
          <div className="container max-w-screen-lg mx-auto py-16">
            {intl.formatMessage(
              {
                id: "contactus.heading-text",
                defaultMessage:
                  "<p>Feel free to get in touch with us. We'll get back to you as fast as possible!</p><p>In case you prefer to send us an e-mail instead, please go to our <a>Team Page</a>.</p>",
              },
              {
                p: (text) => <p>{text}</p>,
                a: (text) => (
                  <Link className="font-semibold" to="/team">
                    {text}
                  </Link>
                ),
              }
            )}
          </div>
          <div className="container max-w-screen-lg mx-auto pb-16">
            <form action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="block font-bold mb-1" htmlFor="name">
                  {intl.formatMessage({ id: "contactus.name", defaultMessage: "Your Name" })}
                </label>
                <input
                  {...register("name", { required: true })}
                  className="w-full rounded-md"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                ></input>
                <p className="text-blue-700 font-bold">{errors.name?.type === "required" && "Please let us know how we can address you"}</p>
              </div>
              <div className="mb-3">
                <label className="block font-bold mb-1" htmlFor="email">
                  {intl.formatMessage({ id: "contactus.email", defaultMessage: "Your E-Mail Address" })}
                </label>
                <input
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: intl.formatMessage({ id: "contactus.email-invalid", defaultMessage: "Please enter a valid e-mail address" }),
                    },
                  })}
                  className="w-full rounded-md"
                  id="email"
                  name="email"
                  type="text"
                  placeholder="e-mail"
                ></input>
                <p className="text-blue-700 font-bold">
                  {errors.email?.type === "required" &&
                    intl.formatMessage({
                      id: "contactus.email-required",
                      defaultMessage: "Please let us know your e-mail address, so we can get back to you",
                    })}
                  {errors.email?.message && errors.email?.message}
                </p>
              </div>
              <div className="mb-3">
                <label className="block font-bold mb-1" htmlFor="message">
                  {intl.formatMessage({ id: "contactus.email-yourmessage", defaultMessage: "Your Message" })}
                </label>
                <textarea
                  {...register("message", { required: true })}
                  className="w-full rounded-md h-44"
                  id="message"
                  name="message"
                  placeholder="Your Message"
                ></textarea>
                <p className="text-blue-700 font-bold">{errors.email?.type === "required" && "Please leave us message"}</p>
              </div>
              <button
                className="bg-ucdark text-white border-2 px-3 py-1 hover:bg-gray-500 rounded-md text-lg hover:-translate-y-px mt-2"
                type="submit"
              >
                {intl.formatMessage({ id: "contactus.email-send", defaultMessage: "Send" })}
              </button>
              <span className="pl-5 text-blue-700 font-bold">{status}</span>
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
}
